import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Countdown from 'react-countdown';
import { Link } from 'gatsby';
import { AshTrace } from '../../modules/ash/components/ash-trace';
import { AshCharacter } from '../../modules/ash/components/ash-character';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AshIndexPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer1 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event ended</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer2 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event started</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };
  return (
    <DashboardLayout className={'home-page ash '} game="ash">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Ash Echoes wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Ash Echoes Global - a gacha game published by{' '}
          <OutboundLink href="https://ae.neocraftstudio.com/en" target="_blank">
            Neocraft
          </OutboundLink>
          . Check our guides, tier lists and reviews for characters available in
          the game.
        </h2>
        <p>For the official Ash Echoes Global website go here:</p>
        <div className="centered-links">
          <div>
            <OutboundLink
              href="https://ae.neocraftstudio.com/en"
              target="_blank"
            >
              <StaticImage
                src="../../images/ash/neocraft_logo.webp"
                alt="A-Level"
                className="partner-logo"
              />
            </OutboundLink>
          </div>
          <div>
            <OutboundLink
              href="https://ae.neocraftstudio.com/download"
              target="_blank"
            >
              <Button variant="white">
                <FontAwesomeIcon icon={faDownload} width="18" /> Download Ash
                Echoes
              </Button>
            </OutboundLink>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Important Content" />
      <div className="promoted-content">
        <div className="banner-promo cen">
          <h4>Should you pull Cen Ying?</h4>
          <Link to="/ash-echoes/guides/should-you-pull-cen-ying">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner-promo lor">
          <h4>Should you pull Lorelle?</h4>
          <Link to="/ash-echoes/guides/should-you-pull-lorelle">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner-promo event-1">
          <h4>Voyagers and Everlasting Shadows Event Guide</h4>
          <Link to="/ash-echoes/guides/voyagers-and-everlasting-shadows">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner-promo event-2">
          <h4>Boss Challenge: Sephonis Guide</h4>
          <Link to="/ash-echoes/guides/boss-challenge-sephonis">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
      </div>
      <SectionHeader title={'Event Timeline'} />
      <Row xs={1} xl={2}>
        <Col>
          <div className="tracker-header">
            <h5>Current</h5>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="event-1" eventKey="1020">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">
                  Voyagers and Everlasting Shadow
                </div>
                <div className="countdown">
                  <Countdown date={1732784400000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 13, 2024, 16:00 –
                  November 28, 2024, 03:59 (UTC-5)
                </p>
                <p className="description">
                  <span>Event description</span>: Voyagers and Everlasting
                  Shadows Event stages will be open for a limited time. Players
                  can obtain event rewards by clearing event stages, completing
                  the event missions, and redeeming from Event Store.
                </p>
                <p className="description">
                  <span>Rewards</span>: [SSR - Memory Traces: Traces In The
                  Museum], Transcript: Traces In The Museum, Resonance Clue,
                  Momentary Backflash, Event Commemorative Furniture, etc.
                  (Echomancer Cen Ying can have special interaction with the
                  following event furnitures: Animal Wood Carving - Souvenir,
                  Ancient Pottery Jar - Souvenir, Bronze Mirror - Souvenir)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SSR">SSR</span>{' '}
                  Memory Trace reward:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Traces In The Museum" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="event-2" eventKey="1021">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Museum Defense</div>
                <div className="countdown">
                  <Countdown date={1732784400000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 13, 2024, 16:00 –
                  November 28, 2024, 03:59 (UTC-5)
                </p>
                <p className="description">
                  <span>Event description</span>: A mysterious figure lurking in
                  the museum, ready to make a move! When counterfeit antiques
                  cause trouble in the museum, use Spell Cards along with
                  Security Devices in the scene to resist the attacks of
                  counterfeits and defend the Museum Terminal! Obtain event
                  rewards by completing the event missions!
                </p>
                <p className="description">
                  <span>Rewards</span>: Special Exhibition Ticket
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-1" eventKey="1010">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Gleaming Light (Banner)</div>
                <div className="countdown">
                  <Countdown date={1732784400000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 13, 2024, 16:00 –
                  November 28, 2024, 03:59 (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-6">6★</span>{' '}
                  character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="cen-ying"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="ash-rar rarity-5">5★</span>{' '}
                  characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="sephonis"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="sambheka"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="caroline"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-2" eventKey="1011">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Born From Ashes (Banner)</div>
                <div className="countdown">
                  <Countdown date={1732784400000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 13, 2024, 16:00 –
                  November 28, 2024, 03:59 (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SSR">SSR</span>{' '}
                  Memory Trace:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Born From Ashes" mode="data" />
                </div>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SR">SR</span> Memory
                  Traces:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Wish In The Rain" mode="data" />
                </div>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Where The Star Fell" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-3" eventKey="1012">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Standard Resonance (Banner)</div>
                <div className="countdown">
                  <Countdown date={1733389200000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 13, 2024, 16:00 –
                  December 5, 03:59 AM (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-6">6★</span>{' '}
                  character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="lorelle"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="discord" eventKey="1090">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Discord Giveaway Event</div>
                <div className="countdown">
                  <Countdown date={1733461200000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 5, 12:00 A.M. (UTC-5) -
                  December 5, 11:59 P.M. (UTC-5)
                </p>
                <p className="description">
                  <span>Event description</span>: Participate the exclusive
                  Discord event to claim the 5★Character "Sambheka" for the
                  upcoming release of Ash Echoes Global. More details you can
                  find{' '}
                  <a
                    href="https://ae.neocraftstudio.com/article/246/en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
                <p className="featured">
                  Free <span className="ash-rar rarity-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="sambheka"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="project" eventKey="1030">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Project Vein (Battle Pass)</div>
                <div className="countdown">
                  <Countdown date={1734944400000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 13, 2024, 16:00 -
                  December 23, 2024, 4:00 (UTC-5).
                </p>
                <p className="description">
                  <span>Event description</span>: During this event, Directors
                  can level up Project Vein Lv. by completing tasks in PROJECT
                  VEIN to gain abundant rewards. Unlock [Advance Plan] to level
                  up your Project Vein Lv. by 10, get the limited SR Memory
                  Trace [Secret Melody], Tea Time Universal Invitations, and
                  advanced supplies!
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SR">SR</span> Memory
                  Trace reward:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Secret Melody" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col>
          <div className="tracker-header">
            <h5>Upcoming</h5>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="event-3" eventKey="1022">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Boss Challenge: Sephonis</div>
                <div className="countdown">
                  <Countdown date={1731661200000} renderer={renderer2} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 15, 2024, 4:00 –
                  November 28, 2024, 03:59 (UTC-5)
                </p>
                <p className="description">
                  <span>Event description</span>: Boss Challenge mode will be
                  available during the time. Directors can select the difficulty
                  level of the boss, and get scores based on the challenge
                  results. Reach specified scores to complete quests and win
                  rewards!
                </p>
                <p className="description">
                  <span>Rewards</span>: Resonance Clue, O.E. Coin(s), and
                  Special Exhibition Tickets, etc
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/ash-echoes/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/ash/categories/category_intro.webp"
              alt="Introduction to the game"
            />
          }
          updated
        />
        <CategoryCard
          title="Beginner Guide"
          link="/ash-echoes/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/ash/categories/category_beginner.webp"
              alt="Beginner Guide"
            />
          }
          isNew
        />
        <CategoryCard
          title="Reroll Guide"
          link="/ash-echoes/guides/reroll"
          image={
            <StaticImage
              src="../../images/ash/categories/category_reroll.webp"
              alt="Reroll Guide"
            />
          }
        />
        <CategoryCard
          title="Elements & Reactions"
          link="/ash-echoes/guides/elements-and-reactions"
          image={
            <StaticImage
              src="../../images/ash/categories/category_elements.webp"
              alt="Elements & Reactions"
            />
          }
        />
        <CategoryCard
          title="Team Building"
          link="/ash-echoes/guides/team-building"
          image={
            <StaticImage
              src="../../images/ash/categories/category_teams.webp"
              alt="Team Building"
            />
          }
          updated
        />
        <CategoryCard
          title="Characters"
          link="/ash-echoes/characters"
          image={
            <StaticImage
              src="../../images/ash/categories/category_characters.webp"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/ash-echoes/tier-list"
          image={
            <StaticImage
              src="../../images/ash/categories/category_tier.webp"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Tea Time Drinks"
          link="/ash-echoes/tea-time"
          image={
            <StaticImage
              src="../../images/ash/categories/category_tea.webp"
              alt="Tea Time Drinks"
            />
          }
          isNew
        />
        <CategoryCard
          title="Game Modes"
          link="/ash-echoes/guides/game-modes"
          image={
            <StaticImage
              src="../../images/ash/categories/category_modes.webp"
              alt="Game Modes"
            />
          }
        />
        <CategoryCard
          title="Echoing Nexus Overview"
          link="/ash-echoes/guides/echoing-nexus"
          image={
            <StaticImage
              src="../../images/ash/categories/category_nexus.webp"
              alt="Echoing Nexus Overview"
            />
          }
        />
        <CategoryCard
          title="Memory Traces"
          link="/ash-echoes/memory-traces"
          image={
            <StaticImage
              src="../../images/ash/categories/category_traces.webp"
              alt="Memory Traces"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AshIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Ash Echoes Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Ash Echoes. Check our guides, tier lists and reviews for characters available in the game."
    game="ash"
  />
);
